import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment"; // Make sure to install moment or use an alternative library
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import useApiAxios from "../api/useApiAxios";
import Loader from "../component/loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import DragsAndDrop from "../image/DragsAndDrop";
import { format } from "date-fns";

const ApplyHere = ({ marketApplyData }) => {
  const defaultHeaderColor = "#3498db";
  const defaultFooterColor = "#e74c3c";

  const headerColor =
    marketApplyData?.marketContent?.headerColor || defaultHeaderColor;
  const footerColor =
    marketApplyData?.marketContent?.footerColor || defaultFooterColor;

  const { applyMarketApi, checkVendorEmailApi } = useApiAxios();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const [
    isPublicLiabilityInsuranceSelected,
    setPublicLiabilityInsuranceSelected
  ] = useState(false);
  const [isFoodRegistrationImageSelected, setFoodRegistrationImageSelected] =
    useState(false);
  const [isLiquorLicenseImageSelected, setLiquorLicenseImageSelected] =
    useState(false);
  const [isOtherLicenseImageSelected, setOtherLicenseImageSelected] =
    useState(false);

  const navigate = useNavigate();

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const FILE_SIZE = 1024 * 1024 * 4; // 4MB

  const validationSchema = Yup.object({
    businessName: Yup.string().required("Required"),
    contactPerson: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
      .required("Required"),
    contactNumber: Yup.string()
      .min(7, "Phone number must be at least 7 digits")
      .max(10, "Phone number cannot exceed 10 digits")
      .required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    category: Yup.string().required("Required"),

    // subCategory: Yup.string().required("Required"),
    // marketDate: Yup.array().min(1, "At least one date must be selected"),
    addProductMessage: Yup.string().required("Required"),
    businessDescription: Yup.string().required("Required"),

    // boothImage: Yup.mixed()
    //   .required("Required")
    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) => value && value.size <= FILE_SIZE
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   ),
    productImage1: Yup.mixed()
      .required("Required")
      .test(
        "fileSize",
        "File too large",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    // productImage2: Yup.mixed()
    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) => value && value.size <= FILE_SIZE
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   ),
    // productImage3: Yup.mixed()
    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) => value && value.size <= FILE_SIZE
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   ),
    publicLiabilityInsurance: Yup.mixed()
      .required("Required")
      .test(
        "fileSize",
        "File too large",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    // foodRegistrationImage: Yup.mixed()
    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) => value && value.size <= FILE_SIZE
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   ),
    // liquorLicenseImage: Yup.mixed()

    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) => value && value.size <= FILE_SIZE
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   ),
    // otherLicenseImage: Yup.mixed()

    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) => value && value.size <= FILE_SIZE
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   ),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    )
  });

  const handleKeyPress = (event, type) => {
    let regex;
    if (type === "numeric") {
      regex = /^[0-9\b]+$/;
      if (!regex.test(event.key) || event.target.value.length >= 10) {
        event.preventDefault();
      }
    } else if (type === "alphabet") {
      regex = /^[A-Za-z\s]+$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    console.log(values.marketDate);
    if (!values.termsAndConditions) {
      // If terms and conditions are not checked, prevent form submission
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("businessName", values.businessName);
      formData.append("businessDescription", values.businessDescription);
      formData.append("contactPerson", values.contactPerson);
      formData.append("contactNumber", values.contactNumber);
      formData.append("addProductMessage", values.addProductMessage);
      formData.append("approvalStatus", "pending");
      formData.append("category", values.category);
      // formData.append("subCategory", values.subCategory);

      values.marketDate.forEach((date) => {
        formData.append("marketDate[]", date);
      });

      formData.append("market", marketApplyData._id);
      // formData.append("boothImage", values.boothImage);
      formData.append("productImage1", values.productImage1);
      formData.append("productImage2", values.productImage2);
      formData.append("productImage3", values.productImage3);
      // Always append publicLiabilityInsurance
      // Always append publicLiabilityInsurance if selected

      if (values.publicLiabilityInsurance) {
        if (values.insuranceExpiryDate) {
          formData.append(
            "publicLiabilityInsurance",
            values.publicLiabilityInsurance
          );
        } else {
          setFieldError("insuranceExpiryDate", "Required");
          return;
        }
      }
      // Conditionally append license images if selected

      if (values.foodRegistrationImage) {
        if (values.expireLicenseDate) {
          formData.append(
            "foodRegistrationImage",
            values.foodRegistrationImage
          );
        } else {
          setFieldError("expireLicenseDate", "Required");
          return;
        }
      }

      if (values.liquorLicenseImage) {
        if (values.liquorLicenseExpiryDate) {
          formData.append("liquorLicenseImage", values.liquorLicenseImage);
        } else {
          setFieldError("liquorLicenseExpiryDate", "Required");
          return;
        }
      }

      if (values.otherLicenseImage) {
        if (values.otherLicenseExpiryDate) {
          formData.append("otherLicenseImage", values.otherLicenseImage);
        } else {
          setFieldError("otherLicenseExpiryDate", "Required");
          return;
        }
      }

      // Conditionally append dates only if they are not equal to default date
      const defaultDate = new Date().toISOString().split("T")[0]; // Default date in 'YYYY-MM-DD' format

      if (
        values.insuranceExpiryDate &&
        values.insuranceExpiryDate.toISOString().split("T")[0] !== defaultDate
      ) {
        formData.append("insuranceExpiryDate", values.insuranceExpiryDate);
      }

      if (
        values.expireLicenseDate &&
        values.expireLicenseDate.toISOString().split("T")[0] !== defaultDate
      ) {
        formData.append("expireLicenseDate", values.expireLicenseDate);
      }
      if (
        values.liquorLicenseExpiryDate &&
        values.liquorLicenseExpiryDate.toISOString().split("T")[0] !==
          defaultDate
      ) {
        formData.append(
          "liquorLicenseExpiryDate",
          values.liquorLicenseExpiryDate
        );
      }
      if (
        values.otherLicenseExpiryDate &&
        values.otherLicenseExpiryDate.toISOString().split("T")[0] !==
          defaultDate
      ) {
        formData.append(
          "otherLicenseExpiryDate",
          values.otherLicenseExpiryDate
        );
      }

      formData.append("facebook", values.facebook);
      formData.append("instagram", values.instagram);
      formData.append("tikTok", values.tikTok);
      formData.append("twitter", values.twitter);

      formData.append("location[coordinates]", "");

      console.log("Data to be sent to the API:", formData);
      setLoading(true);
      setSubmitting(true);
      const response = await applyMarketApi(formData);
      resetForm();
      navigate("/");
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const categories = marketApplyData
    ? marketApplyData.category.map((category) => ({
        label: category.name,
        value: category._id
      }))
    : [];

  const subCategory = marketApplyData
    ? marketApplyData.subCategory.map((subCategory) => ({
        label: subCategory.name,
        value: subCategory._id
      }))
    : [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy");
  };

  const marketDate = marketApplyData
    ? marketApplyData.marketDate.map((dateItem) => ({
        label: formatDate(dateItem.date), // Format date here
        value: dateItem._id
      }))
    : [];

  const checkEmailOnChange = async (e) => {
    const email = e.target.value;
    if (email.length > 0) {
      // Only check if email field is not empty
      setIsCheckingEmail(true);
      const { error } = await checkVendorEmailApi({ email });
      setIsCheckingEmail(false);
      setIsEmailValid(!error);
    } else {
      setIsEmailValid(null); // Reset validity if email is empty
    }
  };

  const handleProduct1ImageChange = (file, setFieldValue) => {
    setFieldValue("productImage1", file);
  };
  const handleProduct2ImageChange = (file, setFieldValue) => {
    setFieldValue("productImage2", file);
  };
  const handleProduct3ImageChange = (file, setFieldValue) => {
    setFieldValue("productImage3", file);
  };
  const handlePublicLiabilityInsuranceChange = (file, setFieldValue) => {
    setFieldValue("publicLiabilityInsurance", file);
    setPublicLiabilityInsuranceSelected(!!file);
  };

  const handleFoodRegistrationImageChange = (file, setFieldValue) => {
    setFieldValue("foodRegistrationImage", file);
    setFoodRegistrationImageSelected(!!file);
  };

  const handleLiquorLicenseImageChange = (file, setFieldValue) => {
    setFieldValue("liquorLicenseImage", file);
    setLiquorLicenseImageSelected(!!file);
  };

  const handleOtherLicenseImageChange = (file, setFieldValue) => {
    setFieldValue("otherLicenseImage", file);
    setOtherLicenseImageSelected(!!file);
  };

  const staticImagePath = process.env.REACT_APP_IMG_URL;

  const headerBannerImageUrl = `${staticImagePath}/${marketApplyData?.marketContent?.headerBannerImage}`;


  const marketApplyHereTitle =  marketApplyData?.marketContent?.applyHereTitle
  // console.warn("marketApplyHereTitle",marketApplyHereTitle)

  return (
    <div>
      <Loader open={loading} />

      <section
        className="slider-area slider-area2"
        style={{
          backgroundImage: `url(${headerBannerImageUrl})`, // Set the background image dynamically
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="slider-active">
          {/* Single Slider */}
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div className="hero__caption hero__caption2 text-center">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Apply Here
                    </h1>
                    <p
  data-animation="bounceIn"
  data-delay="0.4s"
  style={{ fontSize: "20px", lineHeight: "1.6" }}
>
  {marketApplyHereTitle?.trim() && /[a-zA-Z0-9]/.test(marketApplyHereTitle.replace(/<[^>]*>/g, "")) ? (
    <div
      dangerouslySetInnerHTML={{
        __html: marketApplyHereTitle,
      }}
    />
  ) : (
    "Interested in joining a market? You can apply now to be a part of any of the markets listed. Simply choose the one that suits your business and start your journey with us today!"
  )}
</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-services form-contact">
        <div className="container">
          {/* Card Open */}
          <div className="col-md-12">
            <h1 className="font-weight-bold mb-5">Apply Here</h1>
            <Formik
              initialValues={{
                businessName: "",
                contactPerson: "",
                contactNumber: "",
                email: "",
                category: "",
                addProductMessage: "",
                businessDescription: "",
                // boothImage: null,
                publicLiabilityInsurance: null,
                foodRegistrationImage: null,
                liquorLicenseImage: null,
                otherLicenseImage: null,
                productImage1: null,
                productImage2: null,
                productImage3: null,
                facebook: "",
                instagram: "",
                tikTok: "",
                twitter: "",
                expireLicenseDate: null,
                insuranceExpiryDate: null,
                liquorLicenseExpiryDate: null,
                otherLicenseExpiryDate: null,
                termsAndConditions: false,
                marketDate: []
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                setFieldValue,
                isSubmitting,
                values,
                handleChange,
                handleBlur,
                errors
              }) => (
                <Form>
                  {console.log(errors)}
                  {/* <div>{console.warn("Form values:", values)}</div> */}
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Business Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="businessName"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="businessName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/*/Col*/}
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Contact Person <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="contactPerson"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="contactPerson"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="contactNumber"
                        className="form-control"
                        onKeyPress={(e) => handleKeyPress(e, "numeric")}
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/*/Col*/}
                    {/* <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                        onKeyPress={(e) => handleKeyPress(e, "alphabet")}
                        onChange={checkEmailOnChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className={`form-control ${
                          isEmailValid === false ? "is-invalid" : ""
                        }`}
                        onChange={(e) => {
                          handleChange(e);
                          checkEmailOnChange(e);
                        }}
                        onBlur={handleBlur}
                      />

                      {isEmailValid === false && (
                        <div className="text-danger">Email already exists</div>
                      )}
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Category <span className="text-danger">*</span>
                      </label>
                      {subCategory.map((category, index) => (
                        <div
                          className="form-check-inline mr-3"
                          key={index}
                          style={{ padding: "5px" }}
                        >
                          <Field
                            type="radio"
                            name="subCategory"
                            value={category.value}
                            id={category.value}
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor={category.value}
                          >
                            {category.label}
                          </label>
                        </div>
                      ))}
                      <ErrorMessage
                        name="subCategory"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}

                    {/* <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Category <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        name="subCategory"
                        className="form-control"
                      >
                        <option value="">Select a category</option>
                        {subCategory.map((category, index) => (
                          <option key={index} value={category.value}>
                            {category.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="subCategory"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}

                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Category <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        name="category"
                        className="form-control"
                      >
                        <option value="">Select a Category</option>
                        {categories.map((category, index) => (
                          <option key={index} value={category.value}>
                            {category.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Sub Category <span className="text-danger">*</span>
                      </label>
                      {categories.map((category, index) => (
                        <div
                          className="form-check-inline mr-3"
                          key={index}
                          style={{ padding: "5px" }}
                        >
                          <Field
                            type="radio"
                            name="category"
                            value={category.value}
                            id={category.value}
                            className="form-check-input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor={category.value}
                          >
                            {category.label}
                          </label>
                        </div>
                      ))}
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}

                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Add Product Message{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="addProductMessage"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="addProductMessage"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Business Description{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="businessDescription"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="businessDescription"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Facebook</label>
                      <Field
                        type="text"
                        name="facebook"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="facebook"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Instagram</label>
                      <Field
                        type="text"
                        name="instagram"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="instagram"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>TikTok</label>
                      <Field
                        type="text"
                        name="tikTok"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="tikTok"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Twitter</label>
                      <Field
                        type="text"
                        name="twitter"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="twitter"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>
                        Booth Image
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        name="boothImage"
                        className="form-control"
                        accept=".jpg, .jpeg, .png" // Add this line
                        onChange={(event) => {
                          setFieldValue(
                            "boothImage",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="boothImage"
                        component="div"
                        className="text-danger"
                      />
                    </div> */}
                    {/*/Col*/}
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Product Image 1</label>
                      <span className="text-danger">*</span>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="Image"
                        aspect={4.2 / 3}
                        uploadFile={(file) =>
                          handleProduct1ImageChange(file, setFieldValue)
                        }
                      />

                      <ErrorMessage
                        name="productImage1"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/*/Col*/}
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Product Image 2</label>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="Image"
                        aspect={4.2 / 3}
                        uploadFile={(file) =>
                          handleProduct2ImageChange(file, setFieldValue)
                        }
                      />
                      <ErrorMessage
                        name="productImage2"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/*/Col*/}
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Product Image 3</label>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="Image"
                        aspect={4.2 / 3}
                        uploadFile={(file) =>
                          handleProduct3ImageChange(file, setFieldValue)
                        }
                      />
                      <ErrorMessage
                        name="productImage3"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/*/Col*/}
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Public Liability Insurance</label>
                      <span className="text-danger">*</span>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="publicLiabilityInsurance"
                        aspect={1 / 1.414}
                        uploadFile={(file) =>
                          handlePublicLiabilityInsuranceChange(
                            file,
                            setFieldValue
                          )
                        }
                      />

                      {isPublicLiabilityInsuranceSelected && (
                        <>
                          <label>
                            Public Liability Insurance Date
                            <span className="text-danger">*</span>
                          </label>

                          <DatePicker
                            minDate={new Date()}
                            selected={values.insuranceExpiryDate || null}
                            onChange={(date) =>
                              setFieldValue("insuranceExpiryDate", date || null)
                            }
                            placeholderText="Select Date"
                            className="form-control m-2"
                            aria-required="true"
                            dateFormat="dd/MM/yyyy"
                          />
                          <ErrorMessage
                            name="insuranceExpiryDate"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                      <ErrorMessage
                        name="publicLiabilityInsurance"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/* {isPublicLiabilityInsuranceSelected && (
                      <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                        <label>
                          Public Liability Insurance Date
                          <span className="text-danger">*</span>
                        </label>

                        <DatePicker
                          minDate={new Date()}
                          selected={values.insuranceExpiryDate || null}
                          onChange={(date) =>
                            setFieldValue("insuranceExpiryDate", date || null)
                          }
                          placeholderText="Select Date"
                          className="form-control"
                          aria-required="true"
                          dateFormat="dd/MM/yyyy"

                        />
                        <ErrorMessage
                          name="insuranceExpiryDate"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    )} */}

                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Food Registration Image</label>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="foodRegistrationImage"
                        aspect={1 / 1.414}
                        uploadFile={(file) =>
                          handleFoodRegistrationImageChange(file, setFieldValue)
                        }
                      />

                      {isFoodRegistrationImageSelected && (
                        <>
                          <label>Food Registration Expire Date</label>
                          <DatePicker
                            minDate={new Date()}
                            selected={values.expireLicenseDate || null}
                            onChange={(date) =>
                              setFieldValue("expireLicenseDate", date || null)
                            }
                            placeholderText="Select Date"
                            className="form-control m-2"
                            aria-required="true"
                            dateFormat="dd/MM/yyyy"
                          />
                          <ErrorMessage
                            name="expireLicenseDate"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}

                      <ErrorMessage
                        name="foodRegistrationImage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* {isFoodRegistrationImageSelected && (
                      <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                        <label>Food Registration Expire Date</label>
                        <DatePicker
                          minDate={new Date()}
                          selected={values.expireLicenseDate || null}
                          onChange={(date) =>
                            setFieldValue("expireLicenseDate", date || null)
                          }
                          placeholderText="Select Date"
                          className="form-control"
                          aria-required="true"
                          dateFormat="dd/MM/yyyy"

                        />
                        <ErrorMessage
                          name="expireLicenseDate"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    )} */}

                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Liquor License Image</label>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="liquorLicenseImage"
                        aspect={1 / 1.414}
                        uploadFile={(file) =>
                          handleLiquorLicenseImageChange(file, setFieldValue)
                        }
                      />
                      {isLiquorLicenseImageSelected && (
                        <>
                          <label>Liquor License Expiry Date </label>

                          <DatePicker
                            minDate={new Date()}
                            selected={values.liquorLicenseExpiryDate || null}
                            onChange={(date) =>
                              setFieldValue(
                                "liquorLicenseExpiryDate",
                                date || null
                              )
                            }
                            placeholderText="Select Date"
                            className="form-control m-2"
                            aria-required="true"
                            dateFormat="dd/MM/yyyy"
                          />
                          <ErrorMessage
                            name="liquorLicenseExpiryDate"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                      <ErrorMessage
                        name="liquorLicenseImage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* {isLiquorLicenseImageSelected && (
                      <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                        <label>
                          Liquor License Expiry Date{" "}
                          <span className="text-danger">*</span>
                        </label>

                        <DatePicker
                          minDate={new Date()}
                          selected={values.liquorLicenseExpiryDate || null}
                          onChange={(date) =>
                            setFieldValue(
                              "liquorLicenseExpiryDate",
                              date || null
                            )
                          }
                          placeholderText="Select Date"
                          className="form-control"
                          aria-required="true"
                          dateFormat="dd/MM/yyyy"

                        />
                        <ErrorMessage
                          name="liquorLicenseExpiryDate"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    )} */}

                    <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                      <label>Other License Image</label>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="otherLicenseImage"
                        aspect={1 / 1.414}
                        uploadFile={(file) =>
                          handleOtherLicenseImageChange(file, setFieldValue)
                        }
                      />
                      {isOtherLicenseImageSelected && (
                        <>
                          <label>Other License Expiry Date</label>
                          <DatePicker
                            minDate={new Date()}
                            selected={values.otherLicenseExpiryDate || null}
                            onChange={(date) =>
                              setFieldValue(
                                "otherLicenseExpiryDate",
                                date || null
                              )
                            }
                            placeholderText="Select Date"
                            className="form-control m-2"
                            aria-required="true"
                            dateFormat="dd/MM/yyyy"
                          />
                          <ErrorMessage
                            name="otherLicenseExpiryDate"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                      <ErrorMessage
                        name="otherLicenseImage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* {isOtherLicenseImageSelected && (
                      <div className="col-lg-4 col-md-4 col-sm-6 mb-3">
                        <label>Other License Expiry Date</label>
                        <DatePicker
                          minDate={new Date()}
                          selected={values.otherLicenseExpiryDate || null}
                          onChange={(date) =>
                            setFieldValue(
                              "otherLicenseExpiryDate",
                              date || null
                            )
                          }
                          placeholderText="Select Date"
                          className="form-control"
                          aria-required="true"
                          dateFormat="dd/MM/yyyy"

                        />
                        <ErrorMessage
                          name="otherLicenseExpiryDate"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    )} */}
                    {/*/Col*/}
                  </div>

                  <div>
                    {showModal && (
                      <div
                        className="modal"
                        style={{
                          display: "block",
                          backgroundColor: "rgba(0,0,0,0.4)"
                        }}
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          style={{
                            maxWidth: "80%",
                            width: "auto",
                            margin: "20px"
                          }}
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">
                                Terms & Conditions
                              </h5>
                              <button
                                type="button"
                                className="close"
                                onClick={() => setShowModal(false)}
                              >
                                <span>&times;</span>
                              </button>
                            </div>
                            <div
                              className="modal-body"
                              style={{ maxHeight: "70vh", overflowY: "auto" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    marketApplyData.marketContent
                                      .termsAndConditions
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* date */}
                  <div className="row">
                    <div className="col-lg-12">
                      <label>Market Date</label>
                      {marketDate.map((marketDateItem, index) => (
                        <div
                          className="form-check-inline mr-3"
                          key={index}
                          style={{ padding: "5px" }}
                        >
                          <Field
                            type="checkbox"
                            name="marketDate"
                            value={marketDateItem.value}
                            id={marketDateItem.value}
                            className="form-check-input"
                            onChange={(e) => {
                              const { checked, value } = e.target;
                              setFieldValue(
                                "marketDate",
                                checked
                                  ? [...values.marketDate, value]
                                  : values.marketDate.filter((v) => v !== value)
                              );
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={marketDate.date}
                          >
                            {marketDateItem.label}
                          </label>
                        </div>
                      ))}
                      <ErrorMessage
                        name="marketDate"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="row mx-1">
                    <div className="col-lg-12">
                      <label className="form-check-label">
                        <Field
                          type="checkbox"
                          name="termsAndConditions"
                          className="form-check-input "
                        />
                        I agree to the{" "}
                        <button
                          type="button"
                          onClick={() => setShowModal(true)}
                          style={{
                            color: "blue",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            padding: "0" // Adjust padding as needed
                          }}
                        >
                          Terms & Conditions
                        </button>{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <ErrorMessage
                        name="termsAndConditions"
                        component="div"
                        className="text-danger mt-2"
                      />
                    </div>

                    <div
                      className="col-lg-12 text-center"
                      style={{ marginTop: "40px" }}
                    >
                      <button
                        type="submit"
                        style={{
                          padding: "15px 30px", // Add horizontal padding for better button size
                          fontSize: "16px",
                          backgroundColor: headerColor || "#007bff", // Fallback to a default color
                          color: "white",
                          border: "none",
                          borderRadius: "25px",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease"
                        }}
                        onMouseOver={
                          (e) =>
                            (e.currentTarget.style.backgroundColor =
                              footerColor || "#0056b3") // Fallback to a default hover color
                        }
                        onMouseOut={
                          (e) =>
                            (e.currentTarget.style.backgroundColor =
                              headerColor || "#007bff") // Revert to default color
                        }
                      >
                        Submit Application
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ApplyHere;
