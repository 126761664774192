import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Container, Row, Col } from "react-bootstrap";

import useApiAxios from "../api/useApiAxios";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import "./meetourvendor.css";
const MeetOurVendor = (marketData) => {
  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const { getMarketVendorFilterApi } = useApiAxios();
  const [searchParams] = useSearchParams();
  const [vendorData, setVendorData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [marketDateData, setMarketDateData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [dateId, setDateId] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [search, setSearch] = useState("");

  const id = searchParams.get("id");
  const dummyImagePath = "/assets/img/dummyImage.avif";

  const headerBannerImageUrl = `${staticImagePath}/${marketData?.marketData?.headerBannerImage}`;
  const meetOurVendorTitle = marketData?.marketData?.meetOurVendorTitle;

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await getMarketVendorFilterApi(
          id,
          search,
          selectedCategoryId,
          dateId
        );
        const vendors = response.data.marketVendor;
        const categories = response.data.market.subCategory;
        const marketDate = response.data.marketDate;
        setCategoryData(categories);
        setVendorData(vendors);
        setMarketDateData(marketDate);
      } catch (error) {
        console.error(error);
      }
    };

    fetchVendors();
  }, [id, search, selectedCategoryId, dateId]);
  console.log(search);
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
  };

  const sortVendorsAlphabetically = (vendors) => {
    return [...vendors].sort((a, b) =>
      (a.businessName || "").localeCompare(b.businessName || "")
    );
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVendor(null);
  };
  return (
    <div>
      <section
        className="slider-area slider-area2"
        style={{
          backgroundImage: `url(${headerBannerImageUrl})`, // Set the background image dynamically
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="slider-active">
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-11 col-md-12">
                  <div className="hero__caption hero__caption2 text-center">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Meet Our Vendors
                    </h1>
                    <p
                      data-animation="bounceIn"
                      data-delay="0.4s"
                      style={{ fontSize: "20px", lineHeight: "1.6" }}
                    >
                      {meetOurVendorTitle?.trim() &&
                      /[a-zA-Z0-9]/.test(
                        meetOurVendorTitle.replace(/<[^>]*>/g, "")
                      ) ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: meetOurVendorTitle
                          }}
                        />
                      ) : (
                        "Discover the amazing vendors available at the market. From handmade goods to local produce, our vendors offer unique products and services."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-services">
        <div className="container">
          <div className="vendor-tab">
            <ul className="nav nav-tabs custum_tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#tabs-1"
                  role="tab"
                >
                  Display by Category
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tabs-2"
                  role="tab"
                >
                  Display Alphabetically
                </a>
              </li>
            </ul>
            <div className="tab-content p-4 border rounded form-contact">
              <div className="tab-pane active" id="tabs-1" role="tabpanel">
                <div className="row mt-5">
                  <div className="col-md-3 col-sm-4 mb-3">
                    <input
                      type="text"
                      placeholder="Search by name or product"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 col-sm-4 mb-3">
                    <select
                      className="form-control"
                      value={selectedCategoryId}
                      onChange={handleCategoryChange}
                    >
                      <option value="">Select Category</option>
                      {categoryData.map((category, index) => (
                        <option key={index} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-4 mb-3">
                    <select
                      className="form-control"
                      onChange={(e) => setDateId(e.target.value)}
                    >
                      <option value={""}>Select Date</option>
                      {marketDateData.map((dateName, index) => (
                        <option key={index} value={dateName._id}>
                          {formatDate(dateName.date)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr />
                <div className="row">
                  {vendorData.map((vendor) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 mb-30 align-self-stretch"
                      key={vendor._id}
                      onClick={() => handleVendorClick(vendor)}
                    >
                      <div className="single-services h-100">
                        <a href="#!">
                          <div className="cat_img">
                            <img
                              src={`${staticImagePath}/${
                                vendor.productImage1 || dummyImagePath
                              }`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = dummyImagePath;
                              }}
                              title={vendor.businessName || "N/A"}
                            />
                          </div>
                          <h3 className="font-weight-bold">
                            {vendor.businessName || "N/A"}
                          </h3>
                          <p className="content_fixed">
                            {vendor.businessDescription || "N/A"}
                          </p>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tab-pane" id="tabs-2" role="tabpanel">
                <div className="row mt-5">
                  <div className="col-md-3 col-sm-4 mb-3">
                    <input
                      type="text"
                      placeholder="Search by name or product"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 col-sm-4 mb-3">
                    <select className="form-control">
                      <option value={""}>Select Date</option>
                      {marketDateData.map((dateName, index) => (
                        <option key={index} value={dateName._id}>
                          {formatDate(dateName.date)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr />
                <div className="row">
                  {sortVendorsAlphabetically(vendorData).map((vendor) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 mb-30 align-self-stretch"
                      key={vendor._id}
                      onClick={() => handleVendorClick(vendor)}
                    >
                      <div className="single-services h-100">
                        <a href="#!">
                          <div className="cat_img">
                            <img
                              src={`${staticImagePath}/${
                                vendor.productImage1 || dummyImagePath
                              }`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = dummyImagePath;
                              }}
                              title={vendor.businessName || "N/A"}
                            />
                          </div>
                          <h3 className="font-weight-bold">
                            {vendor.businessName || "N/A"}
                          </h3>
                          <p className="content_fixed">
                            {vendor.businessDescription || "N/A"}
                          </p>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Vendor Details Modal */}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>
            {selectedVendor?.businessName || "Vendor Details"}
          </Modal.Title>
          <button type="button" className="close" onClick={handleCloseModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <Container fluid>
            <Row>
              <Col md={4}>
                <img
                  src={`${staticImagePath}/${
                    selectedVendor?.productImage1 || dummyImagePath
                  }`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = dummyImagePath;
                  }}
                  alt={selectedVendor?.businessName || "N/A"}
                  className="vendor-image"
                />
              </Col>
              <Col md={8}>
                <p>
                  <strong>Description:</strong>{" "}
                  {selectedVendor?.businessDescription || "N/A"}
                </p>
                <p>
                  <strong>Contact Person:</strong>{" "}
                  {selectedVendor?.contactPerson || "N/A"}
                </p>
                <p>
                  <strong>Contact Number:</strong>{" "}
                  {selectedVendor?.contactNumber || "N/A"}
                </p>
                <p>
                  <strong>Email:</strong> {selectedVendor?.email || "N/A"}
                </p>
                <p>
                  <strong>Category:</strong>{" "}
                  {selectedVendor?.subCategory[0]?.name || "N/A"}
                </p>
                <p>
                  <strong>subCategory:</strong>{" "}
                  {selectedVendor?.category[0]?.name || "N/A"}
                </p>

                <p>
                  <strong>Additional Product Message:</strong>{" "}
                  {selectedVendor?.addProductMessage || "N/A"}
                </p>
                <p>
                  <strong>Instagram:</strong>{" "}
                  <a
                    href={selectedVendor?.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue" }}
                  >
                    {selectedVendor?.instagram || "N/A"}
                  </a>
                </p>
                <p>
                  <strong>Facebook:</strong>{" "}
                  <a
                    href={selectedVendor?.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue" }}
                  >
                    {selectedVendor?.facebook || "N/A"}
                  </a>
                </p>
                <p>
                  <strong>Twitter:</strong>{" "}
                  <a
                    href={selectedVendor?.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue" }}
                  >
                    {selectedVendor?.twitter || "N/A"}
                  </a>
                </p>
                <p>
                  <strong>TikTok:</strong>{" "}
                  <a
                    href={selectedVendor?.tikTok}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue" }}
                  >
                    {selectedVendor?.tikTok || "N/A"}
                  </a>
                </p>
                <p>
                  <strong>Approval Status:</strong>{" "}
                  {selectedVendor?.approvalStatus || "N/A"}
                </p>
                <p>
                  <strong>Insurance Expiry Date:</strong>{" "}
                  {selectedVendor?.insuranceExpiryDate
                    ? new Date(
                        selectedVendor.insuranceExpiryDate
                      ).toLocaleDateString()
                    : "N/A"}
                </p>
                <p>
                  <strong>Liquor License Expiry Date:</strong>{" "}
                  {selectedVendor?.liquorLicenseExpiryDate
                    ? new Date(
                        selectedVendor.liquorLicenseExpiryDate
                      ).toLocaleDateString()
                    : "N/A"}
                </p>
                <p>
                  <strong>Expire License Date:</strong>{" "}
                  {selectedVendor?.expireLicenseDate
                    ? new Date(
                        selectedVendor.expireLicenseDate
                      ).toLocaleDateString()
                    : "N/A"}
                </p>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <h5>Product Images</h5>
                <Row>
                  <Col md={4}>
                    <img
                      src={`${staticImagePath}/${
                        selectedVendor?.productImage1 || dummyImagePath
                      }`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImagePath;
                      }}
                      alt="Product Image 1"
                      className="product-image"
                    />
                  </Col>
                  <Col md={4}>
                    <img
                      src={`${staticImagePath}/${
                        selectedVendor?.productImage2 || dummyImagePath
                      }`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImagePath;
                      }}
                      alt="Product Image 2"
                      className="product-image"
                    />
                  </Col>
                  <Col md={4}>
                    <img
                      src={`${staticImagePath}/${
                        selectedVendor?.productImage3 || dummyImagePath
                      }`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImagePath;
                      }}
                      alt="Product Image 3"
                      className="product-image"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row className="mt-4">
              <Col>
                <h5>Insurance Images</h5>
                <Row>
                  <Col md={4}>
                    <img
                      src={`${staticImagePath}/${selectedVendor?.publicLiabilityInsurance || dummyImagePath}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImagePath;
                      }}
                      alt="publicLiabilityInsurance"
                      className="product-image"
                    />
                  </Col>
                  <Col md={4}>
                    <img
                      src={`${staticImagePath}/${selectedVendor?.liquorLicenseImage || dummyImagePath}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImagePath;
                      }}
                      alt="liquorLicenseImage"
                      className="product-image"
                    />
                  </Col>
                  <Col md={4}>
                    <img
                      src={`${staticImagePath}/${selectedVendor?.otherLicenseImage || dummyImagePath}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = dummyImagePath;
                      }}
                      alt="otherLicenseImage"
                      className="product-image"
                    />
                  </Col>
                </Row>
              </Col>
            </Row> */}
          </Container>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MeetOurVendor;
